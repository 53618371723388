<!--
Component name: checkList

Description: Display a checklist with the actions to be performed to fully configure the current site.
             The actions are listed below:<template>
               - Define the site location
               - Create at least one building
               - Create at least one floor in the building
               - Define the relevant indoor area(s) on at least one floor of the building
               - Define at least 3 gateways and locate them
               - Create at least 1 autocalibration tags and locate it
               - Create an asset
-->
<template>
    <div id="checkListPortlet" class="kt-portlet kt-portlet--height-fluid">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <span class="kt-portlet__head-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z" fill="#000000" />
                            <path d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z" fill="#000000" opacity="0.3" />
                        </g>
                    </svg>
                </span>
                <h3 class="kt-portlet__head-title">
                    {{ $t("site_checkList") }}
                </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
                <router-link
                    :to="{ name: 'deploymentChecking' }"
                    tag="a"
                    class="btn btn-brand btn-bold btn-sm paddingTop8"
                >
                    <i class="la la-check marginTMinus4"></i>
                    {{ $t("troubleshooting_deploymentChecking") }}
                </router-link>
                <a v-if="this.releasedFor == 'ALE'" target="_blank" rel="noopener" href="/doc/omniAccess_Stellar_Asset_Tracking_deployment_guide.pdf" id="downloadDeploymentGuideButton" class="btn btn-outline-brand btn-sm btn-bold kt-margin-l-5 kt-margin-r-5">
                    <i class="fa fa-file-pdf kt-margin-t-2 kt-margin-r-5"></i> {{ $t("common_deploymentGuide") }}
                </a>
                <a v-else target="_blank" rel="noopener" href="/doc/han/Wireless Asset Tracking 1.0.3 deployment guide.pdf" id="downloadDeploymentGuideButton" class="btn btn-outline-brand btn-sm btn-bold kt-margin-l-5 kt-margin-r-5">
                    <i class="fa fa-file-pdf kt-margin-t-2 kt-margin-r-5"></i> {{ $t("common_deploymentGuide") }}
                </a>
                <button id="cl_collapse" @click="onUpdateCollapse()" class="btn btn-outline-brand btn-sm btn-icon">
                    <i class="la " v-bind:class="[isNotCollapse ? 'la la-angle-up' : 'la la-angle-down']"></i>
                </button>
            </div>
        </div>
        <!--begin:: Widgets/Tasks -->
        <div class="kt-portlet__body">
            <div class="tab-content">
                <div class="tab-pane active" id="kt_widget2_tab1_content" v-show="showCheckList">
                    <div class="kt-section" v-show="progressBarWidth < 100 || isNotCollapse">
                        <div class="kt-section__content">
                            <div class="progress height14">
                                <div class="font600 progress-bar bg-success" role="progressbar" v-bind:style="styleProgressBar" :aria-valuenow="progressBarWidth" aria-valuemin="0" aria-valuemax="100">{{ progressBarWidth }} %</div>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget2">
                        <!--begin:: Checklist object except ProductionMode -->
                        <div v-for="obj in checkList" v-bind:key="obj.name" class="kt-widget2__item" v-bind:class="[obj.isChecked ? 'kt-widget2__item--success' : 'kt-widget2__item--danger']" v-show="isNotCollapse">
                            <div class="kt-widget2__checkbox"></div>
                            <i class="fa kt-margin-r-10" v-bind:class="[obj.isChecked ? 'fa-check text-success' : 'fa-times text-danger']"></i>
                            <div class="kt-widget2__info">
                                <router-link :to="{ name: getNamedRoute(obj.name), params: { fromAction: 'checkList' } }" class="kt-widget2__title" v-bind:class="[obj.isChecked ? 'text-success' : 'text-danger']" id="cl_linkHrefLocalisation">
                                    {{ getTitle(obj.name) }}
                                </router-link>
                                <div class="kt-widget2__username">{{ getDetails(obj.name) }}</div>
                            </div>
                            <div class="kt-widget2__actions" :style="{ visibility: !obj.isChecked && obj.showConfigure ? 'visible' : 'hidden' }">
                                <button v-bind:id="'cl_' + obj.name + 'ConfigButton'" type="button" @click="gotoMenuItem(getNamedRoute(obj.name))" class="btn btn-outline-info btn-sm kt-margin-l-10">
                                    {{ $t("common_configure") }}
                                </button>
                            </div>
                        </div>
                        <!--end:: Checklist object except ProductionMode -->
                        <!--begin:: ProductionMode -->
                        <div class="kt-widget2__item" v-bind:class="[isProdModeActive ? 'kt-widget2__item--success' : 'kt-widget2__item--danger']">
                            <div class="kt-widget2__checkbox"></div>
                            <i class="fa kt-margin-r-10" v-bind:class="[isProdModeActive ? 'fa-check text-success' : 'fa-times text-danger']"></i>
                            <div class="kt-widget2__info">
                                <span class="kt-widget2__title" v-bind:class="[isProdModeActive ? 'text-success' : 'text-danger']">
                                    {{ $t("site_productionMode") }}
                                </span>
                                <div class="kt-widget2__username">{{ $t("site_productionModeDetails") }}</div>
                            </div>
                            <div class="kt-widget2__actions" :style="{ visibility: getVisibleSwitchProdMode() }">
                                <span class="kt-switch kt-switch--md kt-switch--icon">
                                    <label v-if="isHasAndTrialSubscription || this.isProdModeActive || (isHasAndTrialSubscription && !isSubscriptionTerminated)">
                                        <input id="cl_toggleProductionModeButton" v-model="isProdModeActive" type="checkbox" />
                                        <span></span>
                                    </label>
                                    <label v-else data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('error_unauthorizedAction')">
                                        <input id="cl_toggleProductionModeButton" :disabled="!isHasAndTrialSubscription" v-model="isProdModeActive" type="checkbox" />
                                        <span></span>
                                    </label>
                                </span>
                            </div>
                        </div>
                        <!--end:: Production Mode -->
                        <app-gatewaysemissionmodal v-bind:gateways="gatewaysWhichEmit"></app-gatewaysemissionmodal>
                    </div>
                </div>
            </div>
        </div>
        <!--end:: Widgets/Tasks -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../../i18n";
import GatewaysWhichEmitModal from "../../modals/gatewayswhichemitmodal";

const _checkListObj = {
    Location: {
        name: "Location",
        namedRoute: "siteLocation",
        title: "site_siteLocation",
        details: "error_accessDeniedSiteLocation"
    },
    Building: {
        name: "Building",
        namedRoute: "newBuilding",
        title: "b&f_buildings",
        details: "site_checkListBuildingDetails",
        mandatoryName: "Location"
    },
    Floor: {
        name: "Floor",
        namedRoute: "buildings",
        title: "b&f_floors",
        details: "site_checkListFloorDetails",
        mandatoryName: "Building"
    },
    IndoorArea: {
        name: "IndoorArea",
        namedRoute: "indoorAreas",
        title: "area_indoorAreas",
        details: "error_accessDeniedMissingIndoorArea",
        mandatoryName: "Floor"
    },
    Gateway: {
        name: "Gateway",
        namedRoute: "gateways",
        title: "gtw_bleGateways",
        details: "site_checkListGatewayDetails"
    },
    GatewayLocation: {
        name: "GatewayLocation",
        namedRoute: "gatewaysLocation",
        title: "gtw_bleGatewaysLocation",
        details: "site_checkListGatewayLocationDetails",
        mandatoryName: "Gateway"
    },
    Tag: {
        name: "Tag",
        namedRoute: "tags",
        title: "tag_bleTags",
        details: "site_checkListTagDetails"
    },
    AutocalibrationTag: {
        name: "AutocalibrationTag",
        namedRoute: "autocalibrationtags",
        title: "act_autocalibrationtags",
        details: "site_checkListCalibTagDetails"
    },
    AutocalibrationTagLocation: {
        name: "AutocalibrationTagLocation",
        namedRoute: "autocalibrationtagsLocation",
        title: "act_autocalibrationtagsLocation",
        details: "site_checkListCalibTagLocationDetails",
        mandatoryName: "AutocalibrationTag"
    },
    Asset: {
        name: "Asset",
        namedRoute: "assets",
        title: "asset_assets",
        details: "site_checkListAssetDetails"
    }
};

export default {
    name: "checkList",
    props: ["pSite", "pSiteBuildings", "pSiteIndoorAreas", "pSiteBLEGateways", "pSiteTags", "pSiteAutoCalibrationTags", "pSiteAssets"],
    data() {
        return {
            releasedFor: process.env.VUE_APP_RELEASED_FOR,
            checkList: [],
            showCheckList: false,
            progressBarWidth: 0,
            styleProgressBar: { width: "0%", color: "black", "margin-left": "10px" },
            atLeast3LocatedGatewaysByFloor: null,
            isProdModeCheck: false,
            isProdModeActive: false,
            isNotCollapse: true,
            gatewaysWhichEmit: [],
            isHasAndTrialSubscription: false
        };
    },
    created: function() {
        console.log("Component(CheckList)::created() - called");
        this.getCheckList();
        this.getSiteSubscription({ siteId: this.$route.params.siteId, sync: false });
    },
    mounted: function() {
        console.log("Component(CheckList)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        pSite: function(site) {
            console.log("Component(CheckList)::pSite() - watch : ", site);
            if (site && site.calibrationMode === "GATEWAYS_ONLY"){
                // Remove from checkList array autocalibration beacons items
                this.checkList = this.checkList.filter((item) => {
                    return (item.name !== "AutocalibrationTag" && item.name !== "AutocalibrationTagLocation")
                });
            }
            if (site && this.isProdModeActive != site.isInProduction) {
                this.isProdModeActive = site.isInProduction;
            }
            this.isLocatedSite();
            this.initShowCheckList();
        },

        pSiteBuildings: function(buildings) {
            console.log("Component(CheckList)::pSiteBuildings() - watch : ", buildings);
            // Check at least one building
            if (this.isAtLeastNbObject(buildings, "Building", 1)) {
                for (let i = 0; i < buildings.length; i++) {
                    // Check at least one floor
                    if (this.isAtLeastNbObject(buildings[i].floors, "Floor", 1)) {
                        break;
                    }
                }
            }
            this.initShowCheckList();
        },

        pSiteIndoorAreas: function(areas) {
            console.log("Component(CheckList)::pSiteIndoorAreas() - watch : ", areas);
            // Check at least 1 indoor area
            this.isAtLeastNbObject(areas, "IndoorArea", 1);
            this.initShowCheckList();
        },

        pSiteBLEGateways: function(gateways) {
            console.log("Component(CheckList)::pSiteBLEGateways() - watch : ", gateways);
            // Check at least 3 gateways
            if (this.isAtLeastNbObject(gateways, "Gateway", 3)) {
                this.checkLocationOfGatewayAndAutocalibrationTag();
            }
            this.initShowCheckList();
        },

        pSiteTags: function(tags) {
            console.log("Component(CheckList)::pSiteTags() - watch : ", tags);
            // Check at least 1 indoor area
            this.isAtLeastNbObject(tags, "Tag", 1);
            this.initShowCheckList();
        },

        pSiteAutoCalibrationTags: function(tags) {
            console.log("Component(CheckList)::pSiteAutoCalibrationTags() - watch : ", tags);
            if (this.isAtLeastNbObject(tags, "AutocalibrationTag", 1)) {
                this.checkLocationOfGatewayAndAutocalibrationTag();
            }
            this.initShowCheckList();
        },

        pSiteAssets: function(assets) {
            console.log("Component(CheckList)::pSiteAssets() - watch : ", assets);
            let minCount = 1;
            if (_checkListObj["Asset"].mandatoryName === "Tag") {
                // Rule: At least one asset with an associated Tag
                minCount = Number.MAX_SAFE_INTEGER;
                if (assets) {
                    for (let i = 0; i < assets.length; i++) {
                        if (assets[i].tag) {
                            // There is an associated tag
                            minCount = 1;
                            break;
                        }
                    }
                }
            }
            this.isAtLeastNbObject(assets, "Asset", minCount);
            this.initShowCheckList();
        },

        isProdModeActive: function(isActive) {
            if (this.pSite.isInProduction !== isActive) {
                let siteData = {
                    id: this.pSite.id,
                    isInProduction: isActive
                };
                // Update the site in the backend
                this.updateSiteProductionMode(siteData);
            }
        },

        siteSubscription: function() {
            if(_.isEmpty(this.siteSubscription)) {
                this.isHasAndTrialSubscription = false;
            } else {
                if (this.siteSubscription.type === "TRIAL" && !this.isSubscriptionTerminated) {
                    this.isHasAndTrialSubscription = true;

                } else if (this.siteSubscription.type === "PAID") {
                    this.isHasAndTrialSubscription = false;
                }
            }
        },

        showSiteGatewaysEmissionModal: function(showSiteGatewaysEmissionModal) {
            console.log("Component(CheckList)::watch(showGatewaysEmissionModal) called with :", showSiteGatewaysEmissionModal);
            if (showSiteGatewaysEmissionModal) {
                $("#gatewayswhichemit").modal("show");
                this.gatewaysWhichEmit = this.siteGatewaysWhichEmit;
                this.isProdModeActive = false;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["showSiteGatewaysEmissionModal", "siteGatewaysWhichEmit", "siteSubscription", "isSubscriptionTerminated"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["updateSiteProductionMode", "getSiteSubscription"]),

        getCheckList: function() {
            let mySelf = this;
            Object.keys(_checkListObj).forEach(key => {
                let item = {
                    name: key,
                    isChecked: null,
                    showConfigure: false
                };
                mySelf.checkList.push(item);
            });
        },

        getNamedRoute: function(name) {
            return _checkListObj[name].namedRoute;
        },

        getTitle: function(name) {
            return i18n.t(_checkListObj[name].title);
        },

        getDetails: function(name) {
            return i18n.t(_checkListObj[name].details);
        },

        gotoMenuItem: function(namedRoute) {
            console.log("Component(CheckList)::gotoMenuItem( " + namedRoute + " ) - called");
            this.$router.push({ name: namedRoute, params: { fromAction: "checkList" } });
        },

        /**
         * Initialization to show the check list when all params have been received (to avoid several displays with danger then success check).
         */
        initShowCheckList: function() {
            if (!this.showCheckList) {
                if (this.pSite && this.pSiteBuildings && this.pSiteIndoorAreas && this.pSiteBLEGateways && this.pSiteTags && this.pSiteAutoCalibrationTags && this.pSiteAssets) {
                    console.log("Component(CheckList)::initShowCheckList() - All params initialized");
                    this.showCheckList = true;
                }
            }
        },

        /**
         * Update the data checkList and the progress bar width.
         * @param {String} objName Object name ("Building", "IndoorArea", "Gateway", ...)
         * @param {Boolean} objValue The isChecked boolean value to update
         */
        updateCheckList: function(objName, objValue) {
            let countIsChecked = 0;
            for (let i = 0; i < this.checkList.length; i++) {
                if (this.checkList[i].name === objName) {
                    this.checkList[i].isChecked = objValue;
                }
                // Current object check status
                if (this.checkList[i].isChecked) {
                    // Nb success check
                    countIsChecked++;
                } else {
                    // objName is not checked. Display or not "Configure" button according to mandatory configured object
                    let mandatoryObjName = _checkListObj[this.checkList[i].name].mandatoryName;
                    if (objValue && mandatoryObjName == objName) {
                        // Diplay the "Configure" button if the mandatory object is checked
                        this.checkList[i].showConfigure = true;
                    } else if (!mandatoryObjName) {
                        // Display the "Configure" button if there is no mandatory object
                        this.checkList[i].showConfigure = true;
                    }
                }
            }
            // Initialise the progress bar
            this.progressBarWidth = (countIsChecked / this.checkList.length) * 100;
            if (this.progressBarWidth > 0) {
                this.styleProgressBar = { width: this.progressBarWidth + "%", color: "white" };
                if (this.progressBarWidth === 100) {
                    // Force the collapse of check list objects (except Production Mode)
                    this.isNotCollapse = false;
                }
            }
        },

        /**
         * Check if an object name (a key in _checkListObj) has at least a number of defined object
         * @param {Object} pObj Object parameter (pSiteBuildings, pSiteIndoorAreas, pSiteBLEGateways, ...)
         * @param {String} objName Object name ("Building", "IndoorArea", "Gateway", ...)
         * @param {Integer} objMinNumber Minimum number of object
         * @returns Boolean true if object is defined at least nb times, else false
         */
        isAtLeastNbObject(pObj, objName, objMinNumber) {
            let isNbObj = false;
            if (pObj) {
                isNbObj = pObj.length >= objMinNumber ? true : false;
            }
            this.updateCheckList(objName, isNbObj);
            return isNbObj;
        },

        /**
         * Check if the site has been located.
         */
        isLocatedSite: function() {
            let isLocated = null;
            if (this.pSite) {
                isLocated = this.pSite.location ? true : false;
            }
            this.updateCheckList("Location", isLocated);
        },

        /**
         * Get the list of floors id which had at least 3 located gateways.
         * @returns JSON list of gateways number by floor id
         */
        get3LocatedGatewaysByFloor: function() {
            let atLeast3LocatedGatewaysByFloor = {};
            if (this.pSiteBLEGateways) {
                let is3Gateways = this.pSiteBLEGateways.length >= 3 ? true : false;
                if (is3Gateways) {
                    let nbLocatedGatewaysByFloor = {};
                    for (let i = 0; i < this.pSiteBLEGateways.length; i++) {
                        let gateway = this.pSiteBLEGateways[i];
                        if (gateway.floor && gateway.location) {
                            // The gateway.floor can be a floor id or a floor object ...
                            let floorId = gateway.floor.id ? gateway.floor.id : gateway.floor;
                            if (floorId) {
                                if (nbLocatedGatewaysByFloor[floorId] === undefined) {
                                    // Add located floor with first gateway
                                    nbLocatedGatewaysByFloor[floorId] = 1;
                                } else {
                                    nbLocatedGatewaysByFloor[floorId]++;
                                    if (nbLocatedGatewaysByFloor[floorId] >= 3) {
                                        atLeast3LocatedGatewaysByFloor[floorId] = nbLocatedGatewaysByFloor[floorId];
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return atLeast3LocatedGatewaysByFloor;
        },

        /**
         * Check 3 gateways and autocalibration tag location on same floor.
         */
        checkLocationOfGatewayAndAutocalibrationTag: function() {
            // Get the list of floors id which had at least 3 located gateways (List of nb gateways by floor id)
            this.atLeast3LocatedGatewaysByFloor = this.get3LocatedGatewaysByFloor();
            console.log("Component(CheckList)::checkLocationOfGatewayAndAutocalibrationTag() - List of nb gateways (at least 3) by floor id : " + JSON.stringify(this.atLeast3LocatedGatewaysByFloor));
            // Init the array of floors id which have at least 3 gateways positioned
            let floorsWith3LocatedGatewaysArray = Object.keys(this.atLeast3LocatedGatewaysByFloor);
            // Check gateway location for at leat 3 gateways on same floor
            this.isAtLeastNbObject(floorsWith3LocatedGatewaysArray, "GatewayLocation", 1);
            // Check autocalibration location for an autocalibration tag on a floor with at least 3 gateways location
            if (this.pSiteAutoCalibrationTags) {
                for (let i = 0; i < this.pSiteAutoCalibrationTags.length; i++) {
                    let acTag = this.pSiteAutoCalibrationTags[i];
                    if (acTag.location && acTag.floor) {
                        // The acTag.floor can be a floor id or a floor object ...
                        let acTagId = acTag.floor.id ? acTag.floor.id : acTag.floor;
                        if (acTagId && this.atLeast3LocatedGatewaysByFloor[acTagId]) {
                            this.isAtLeastNbObject(floorsWith3LocatedGatewaysArray, "AutocalibrationTagLocation", 1);
                        }
                        break;
                    }
                }
            }
        },

        isSiteConfigMinimum: function() {
            if (this.progressBarWidth === 100) {
                return true;
            }
            return false;
        },

        getVisibleSwitchProdMode: function() {
            if (this.isSiteConfigMinimum()) {
                return "visible";
            }
            return "hidden";
        },

        onUpdateCollapse: function() {
            this.isNotCollapse = !this.isNotCollapse;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-gatewaysemissionmodal": GatewaysWhichEmitModal
    }
};
</script>

<style scoped>
.height14 {
    height: 14px !important;
}
.font600 {
    font-weight: 600;
    font-size: 11px;
}
</style>
